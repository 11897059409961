.claim-ad3-modal {
  .ant-modal-content {
    background: #10121A;
    border-radius: 21px;
    padding: 0;
    border: 1px solid #1C1E25;

    .ant-modal-close {
      color: #fff;
      opacity: 0.6;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .header {
    margin-top: 24px;
    text-align: center;
    font-size: 20px;
    line-height: 23px;
    opacity: 0.6;
  }

  .content {
    color: #1DA1F2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px 0;

    .value {
      input {
        border: none;
        background: none;
        outline: none;
        max-width: 100%;
        text-align: center;
        color: #1DA1F2;
        font-size: 80px;
        line-height: 94px;
        letter-spacing: 0.11em;
        font-family: Gilroy-Bold;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .unit {
      font-size: 46px;
      line-height: 54px;
    }
  }

  .footer {
    border-top: 0.5px solid rgba(255, 255, 255, 0.2);
    padding: 24px;
  }
}

.claim-ad3-drawer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    opacity: 0.6;
    margin-bottom: 90px;
  }

  .content {
    color: #1DA1F2;
    font-family: 'Gilroy-Bold';
    display: flex;
    flex-direction: column;
    align-items: center;

    .value {
      input {
        border: none;
        background: none;
        outline: none;
        max-width: 100%;
        text-align: center;
        color: #1DA1F2;
        font-size: 40.7804px;
        line-height: 48px;
        letter-spacing: 0.11em;
        font-family: Gilroy-Bold;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .unit {
      font-size: 32.8797px;
      line-height: 39px;
    }
  }

  .footer {
    margin-top: 100px;
    margin-bottom: 30px;
    width: 100%;
  }
}