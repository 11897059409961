.group-mining-tweet {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #1D202F;
  }

  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .tweet-content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex-grow: 1;

    .user-row {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 28px;

      .user {
        display: flex;
        flex-direction: row;

        &:hover {
          cursor: pointer;
        }

        .username {
          color: #fff;
        }

        .twitter-name {
          margin-left: 10px;
          opacity: 0.5;
        }
      }

      .scores {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
      }

      .promo-tag {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;

        .icon {
          margin-right: 10px;
        }

        .text {
          max-width: 200px;
          color: #FFC700;
          font-size: 18px;
          line-height: 21px;
          font-family: Gilroy-Bold;
        }
      }
    }

    .content-row {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .group-mining-tweet {
    &.section-card {
      padding: 12px;
    }

    .avatar-container {
      .avatar {
        width: 38px;
        height: 38px;
      }
    }

    .tweet-content {
      .user-row {
        font-size: 14px;
        line-height: 16px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 12px;

        .user {
          margin-bottom: 12px;
        }

        .promo-tag {
          .text {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      .content-row {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}