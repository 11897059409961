@import '../../common.scss';

.leaderboard-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 956px;
  min-height: 100vh;
  background-image: url('../../../public/leaderboard-bg-top.png');
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;

  .title {
    font-size: 36px;
    line-height: 42px;
    font-family: Gilroy-Bold;
    font-weight: bold;
  }

  .introduce {
    font-size: 18px;
    line-height: 21px;
    font-family: Gilroy-Medium;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 10px;
    margin-bottom: 14px;
  }

  .dashboard {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .stat-card {
      width: 306px;
      height: 125px;
      background: #10121A;
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 50px;

      .label {
        font-size: 20px;
        line-height: 23px;
        opacity: 0.6;
        margin-bottom: 18px;
        white-space: nowrap;
      }

      .value {
        color: #1DA1F2;
        font-size: 28px;
        line-height: 33px;
        font-family: Gilroy-Bold;
      }
    }
  }

  .leaderboard-tweets {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .leaderboard-tweet {
      display: flex;
      justify-content: center;
      width: 25%;
      padding: 25px 20px;

      &.item-0 {
        width: 100%;
      }

      &.item-1,
      &.item-2,
      &.item-3 {
        width: 26%;
      }
    }

    .loading-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 0;

      p {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
      }

      button {
        background-color: transparent;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 13px;
        padding: 14px 54px;
        cursor: pointer;
        min-width: 202px;
        transition: all 0.3s ease-in-out;

        &:hover {
          border-color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .leaderboard-container {
    padding: 0 16px;

    .title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 35px;
    }

    .dashboard {
      flex-direction: column;
      margin: 0;
      margin-bottom: 20px;

      .stat-card {
        width: 100%;
        padding: 20px;
        height: fit-content;

        &+.stat-card {
          margin-top: 19px;
        }

        .label {
          font-size: 14px;
          line-height: 16px;
          font-weight: unset;
        }

        .value {
          font-size: 20px;
          line-height: 23px;
        }
      }
    }

    .leaderboard-tweets {
      .leaderboard-tweet {
        display: flex;
        justify-content: center;
        width: 25%;
        padding: 25px 20px;

        &.item-0 {
          width: 100%;
        }

        &.item-1,
        &.item-2,
        &.item-3 {
          width: 26%;
        }
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  .leaderboard-container {
    .leaderboard-tweets {
      .leaderboard-tweet {
        display: flex;
        justify-content: center;
        width: 50%;
        padding: 25px 10px;

        &.item-0 {
          width: 100%;
        }

        &.item-1,
        &.item-2,
        &.item-3 {
          width: 50%;
        }
      }
    }
  }
}