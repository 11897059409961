/**
 * @ Author: Hikaru
 * @ Create Time: 2022-03-11 18:07:13
 * @ Modified by: Hikaru
 * @ Modified time: 2022-07-22 00:09:47
 * @ Description: i@rua.moe
 */

.backgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.1;
  }

  canvas {
    max-width: 100%;
    height: 100vh;
  }
}
