.nft-image-container {
  height: 100%;
  width: 100%;
  border-radius: 23px;
  box-sizing: border-box;
  transition: box-shadow 0.1s ease-in-out;
  position: relative;

  &.active:hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px #1DA1F2;

    .boost-tag {
      border-radius: 20px 0 15px 0;
    }
  }

  &.selected {
    box-shadow: 0px 0px 0px 4px #1DA1F2 !important;

    .boost-tag {
      border-radius: 20px 0 15px 0;
    }
  }

  .boost-tag {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background: #1DA1F2;
    padding: 6px 8px 4px 12px;
    border-radius: 23px 0 15px 0;
    transition: border-radius 0.1s ease-in-out;

    .icon {
      margin-right: 5px;
    }
  }

  .svg-container {
    border-radius: 23px;
    width: 100%;
    height: 100%;

    .nft-image {
      height: 100%;
      width: 100%;
      border-radius: 23px;
      box-sizing: border-box;
    }

    .nft-badge-container {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      top: 0;

      img {
        width: 100%;
      }
    }

  }
}

@media only screen and (max-width: 991px) {
  .nft-image-container {
    &.selected {
      box-shadow: 0px 0px 0px 1px #1DA1F2 !important;
    }
  }
}