.boost-modal {
  .ant-modal-content {
    background: #10121A;
    border-radius: 21px;
    padding: 0;
    border: 1px solid #1C1E25;

    .ant-modal-close {
      color: #fff;
      opacity: 0.6;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .header {
    margin: 24px 50px 0 50px;
    font-family: Gilroy-Bold;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .content {
    padding-bottom: 73px;
    display: flex;
    flex-direction: row;

    .boost-section {
      display: flex;
      flex-direction: column;
      padding: 60px;

      .current-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .current-stat {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 75px;

          .stat-label {
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;
            opacity: 0.6;
            margin-bottom: 18px;
          }

          .stat-value {
            font-family: Gilroy-Bold;
            font-size: 30.75px;
            line-height: 36px;
            color: #1DA1F2;
          }
        }
      }

      .boost-btn {
        padding: 20px 140px;
        border: 1px solid #1DA1F2;
        font-family: Gilroy-Bold;
        font-size: 24px;
        line-height: 28px;
        color: #1DA1F2;
        transition: all 0.3s ease-in-out;

        &:hover {
          cursor: pointer;
          background: #1DA1F2;
          color: #FFFFFF;
        }
      }
    }

    .info-section {
      padding: 60px;
      border-left: 1px solid rgba(255, 255, 255, 0.3);

      .info-title {
        font-family: Gilroy-Bold;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 17px;
      }

      .boost-description {
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        opacity: 0.6;
      }
    }
  }
}

.boost-drawer {
  .info-section {
    margin-top: 40px;
    
    .info-title {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 12px;
    }

    .boost-description {
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      opacity: 0.6;
    }
  }

  .boost-section {
    margin-top: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 25px;
    
    .boost-section-title {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 12px;
    }

    .current-stats {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 32px;

      .current-stat {
        display: flex;
        flex-direction: column;
        align-items: center;

        .stat-label {
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
          opacity: 0.6;
          margin-bottom: 12px;
        }

        .stat-value {
          font-family: Gilroy-Bold;
          font-size: 22px;
          line-height: 26px;
          color: #1DA1F2;
        }
      }
    }
  }

  .boost-btn {
    padding: 18px 0;
    text-align: center;
    border: 1px solid #1DA1F2;
    font-family: Gilroy-Bold;
    font-size: 16px;
    line-height: 19px;
    color: #1DA1F2;
    margin-bottom: 40px;
  }
}