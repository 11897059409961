.dashboard-container {
  // width: 100vw;
  // max-width: 956px;
  // min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    font-family: Gilroy-Bold;
    font-size: 36px;
    line-height: 42px;
  }

  .mining-rewards {
    padding: 34px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-title-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 18px;

      .title {
        font-size: 20px;
        line-height: 23px;
        opacity: 0.6;
        margin-right: 10px;
      }

      .tag {
        background: rgba(255, 255, 255, 0.13);
        border-radius: 17px;
        font-size: 12px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.6);
        padding: 2px 8px;
      }

      .tx-log-icon-container {
        flex-grow: 1;
        .tx-log-icon {
          cursor: pointer;
          width: fit-content;
          float: right;
        }
      }
    }

    .reward-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 39px;

      font-size: 28px;
      line-height: 33px;
      font-family: Gilroy-Bold;
      color: #1DA1F2;
    }
  }

  .claim-btn {
    width: 100%;
    max-width: 330px;
    font-size: 14px;
    line-height: 16px;
    padding: 13px;
  }
}

@media only screen and (max-width: 991px) {
  .dashboard-container {
    // padding: 0 16px;
    // min-height: 50vh;

    .heading {
      font-size: 32px;
      line-height: 38px;
    }

    .heading-description {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 35px;
    }

    .mining-rewards {
      padding: 16px;

      .card-title-row {
        .title {
          font-size: 14px;
          line-height: 16px;
        }

        .tag {
          scale: 0.8;
        }
      }

      .reward-row {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
}