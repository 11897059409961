.mobile-drawer.ant-drawer-content {
  color: #ffffff;
  background: #10121A;
  border-radius: 20px 20px 0 0;

  .ant-drawer-body {
    padding: 16px 27px;

    .close-header {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      left: 0;
      z-index: 1;

      .close-icon {
        margin-right: 27px;
      }
    }
  }
}