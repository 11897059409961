.content-container {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  color: #fff;

  .title-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-family: Gilroy-Bold;
      font-size: 102px;
      line-height: 120px;
      margin-bottom: 105px;
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 27.6px;
      line-height: 142.8%;
      margin-bottom: 30px;
      font-family: Gilroy-Bold;

      .superscript {
        font-family: Gilroy-Regular;
        text-align: center;
        position: relative;
        top: -12px;
        font-size: 0.8rem;
        border: 1px solid;
        border-radius: 100%;
        letter-spacing: 0px;
        width: 18px;
        height: 18px;
        display: inline-block;
        line-height: 15px;
      }
    }

    .action-btn-primary {
      font-weight: 600;
      font-size: 20px;
      line-height: 160%;
      padding: 8px 32px;

      .btn-icon {
        margin-right: 10px;
      }
    }
  }

  .origin-section {
    max-height: 100vh;
    height: 1500px;
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 160%;
    }

    .action-btn-primary {
      // padding: 8px 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 160%;
      padding: 8px 32px;

      .btn-icon {
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .content-container {
    .title-section {
      padding: 0px 20px;

      .title {
        font-size: 50px;
        line-height: unset;
      }

      .description {
        font-size: 1rem;
      }

      .action-btn-primary {
        font-size: 0.8rem;
      }
    }

    .origin-section {
      max-height: 80vh;
      padding: 20px;

      .title {
        font-size: 1rem;
      }

      .action-btn-primary {
        font-size: 0.8rem;
      }
    }
  }
}