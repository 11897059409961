.header-container {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 60px;
  z-index: 99;
  background: rgba(2, 5, 16, 0.5);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #3A3B41;

  &.mobile {
    padding: 0 16px;

    .logo-container {
      .logo {
        height: 32px;
      }

      .title {
        font-size: 16px;
        line-height: 19px;
        margin-left: 9px;
      }
    }

    .launch-btn {
      font-size: 16px;
      line-height: 19px;

      .launch-btn-icon {
        margin-right: 8px;
      }
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 270px;

    .logo {
      height: 40px;
    }

    .title {
      color: #fff;
      font-family: Gilroy-Bold;
      font-size: 24px;
      line-height: 28px;
      margin-left: 11px;
      margin-top: 4px;
    }
  }

  .links {
    color: #fff;
    display: flex;
    flex-direction: row;

    .link {
      opacity: 0.6;
      font-size: 20px;
      line-height: 23px;

      &+.link {
        margin-left: 26px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      &.active {
        opacity: 0.8;
      }
    }
  }

  .user-info-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 270px;
    justify-content: flex-end;
  }

  .user-profile {
    height: 50px;
    background: #10121A;
    border: 0.6px solid #1C1E25;
    border-radius: 16px;
    display: flex;
    padding-left: 15px;
    padding-right: 13px;
    align-items: center;

    &+.user-profile {
      margin-left: 12px;
    }

    &:hover {
      background: #1C1E25;
    }

    .network-warning {
      cursor: pointer;
      font-size: 14.5px;
      line-height: 17px;
      color: #E42884;
    }

    .username {
      color: #fff;
      font-size: 14.5px;
      line-height: 17px;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-avatar {
      border-radius: 50%;
      height: 24px;
      width: 24px;
      margin-left: 20px;
    }
  }

  .launch-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;

    .launch-btn-icon {
      display: flex;
      margin-right: 13px;
    }
  }
}

.user-profile-dropdown {
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;

  background: #10121A;
  border: 0.6px solid rgba(255, 255, 255, 0.16);
  border-radius: 9px;

  padding: 30px 35px;
  margin-top: 10px;
  text-align: center;

  .item {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    line-height: 18px;
    user-select: none;

    &:not(.disabled):hover {
      color: rgba(255, 255, 255, 0.9);
      cursor: pointer;
    }

    &+.item {
      margin-top: 18px;
    }
  }

  .logout-btn {
    font-weight: 500;
    opacity: 0.85;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.mobile-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 70px;
  padding: 0 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  background-color: #020510;
  border-top: 1px solid #3A3B41;

  .link {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.6;

    &.active {
      opacity: 0.8;
    }
  }

  .user-profile {
    display: flex;
    align-items: center;

    .user-avatar {
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-dropdown {
    margin-top: 0;
    margin-bottom: 30px;

    .item {
      font-size: 12px;
      line-height: 14px;
    }
  }
}