@import '../../common.scss';

:root {
  --angle: 45deg;
}

.vault-container {

  color: #fff;
  width: 100vw;
  max-width: 956px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .switch-btn {
    background: #FFFFFF21;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 23px;
    padding: 0 14px;
    margin-bottom: 20px;

    .option {
      z-index: 3;
      font-size: 18px;
      line-height: 21px;
      min-width: 114px;
      text-align: center;

      &+.option {
        margin-left: 44px;
      }

      &.active:hover {
        cursor: pointer;
      }
    }

    .knob {
      position: absolute;
      z-index: 2;
      top: 2px;
      left: 2px;
      background: #1DA1F2;
      width: 138px;
      height: 42px;
      border-radius: 21px;
      transition: 0.3s ease all;

      &.solo {
        left: 160px;
      }
    }

    &.kol {
      .knob {
        left: 160px;

        &.solo {
          left: 2px;
        }
      }

    }
  }

  .miner-title {
    font-family: Gilroy-Bold;
    font-size: 36px;
    line-height: 42px;
    width: 100%;
    margin-bottom: 20px;
  }

  .miner-description {
    font-size: 18px;
    line-height: 21px;
    opacity: 0.5;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  .post-tweet {
    // padding: 18px 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .post-info {
      font-size: 24px;
      line-height: 28px;

      .hashtag {
        color: #1DA1F2;
      }
    }

    .twit-btn {
      padding: 13px 16px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .miner-tweet {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 3px solid rgba(255, 255, 255, 0.13);
    border-radius: 21px;
    padding: 35px;
    margin-bottom: 20px;
    background-image: linear-gradient(to right,
        #10121A,
        #10121A),
      conic-gradient(from var(--angle),
        #1DA1F200,
        #1DA1F2,
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    animation: rotate 6s linear infinite;

    .section-card {
      margin-bottom: 0;
    }

    .section-card+.section-card {
      margin-top: 20px;
    }

    .miner-tweet-content {
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.13);
      border-radius: 20px;
      display: flex;
      align-items: center;
      padding: 24px;

      .avatar-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        .avatar {
          width: 60px;
          height: 60px;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &.original {
        .tweet-content {
          margin-left: 0;
          max-width: 100%;
          
          .user-row {
            margin-bottom: 15px;
          }
        }
      }

      .tweet-content {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        max-width: 80%;
        height: 100%;

        .user-row {
          margin-bottom: 5px;
          display: flex;
          flex-direction: column;

          font-size: 24px;
          line-height: 28px;

          &:hover {
            cursor: pointer;
          }

          .username {
            color: #fff;
          }

          .twitter-name {
            opacity: 0.5;
          }
        }

        .replying-row {
          font-size: 20px;
          line-height: 28px;
          color: rgba(255, 255, 255, 0.5);

          .replying-name {
            color: #47CAFF;
            cursor: pointer;
          }
        }

        .content-row {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          width: 100%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .corner-tag {
        position: absolute;
        padding: 5px 10px;
        height: 69px;
        top: -1px;
        right: -1px;
        background: rgba(29, 161, 242, 0.16);
        border: 1px solid rgba(255, 255, 255, 0.13);
        border-radius: 0px 20px 0px 69px;
        padding: 16px 24px 16px 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .timer-label {
          font-size: 18px;
          line-height: 21px;
          color: #FFFFFF;
          opacity: 0.5;
        }

        .timer {
          margin-left: 30px;
          display: flex;
          font-weight: 600;
          font-size: 23.0721px;
          line-height: 23px;
          align-items: center;

          .value {
            min-width: 42px;
            background: rgba(0, 0, 0, 0.96);
            color: #FFFFFF;
            border-radius: 4px;
            padding: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .colon {
            margin: 0 5px;
          }
        }
      }
    }

    .gpt-score {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .label {
        font-family: Gilroy-Bold;
        font-size: 24px;
        line-height: 28px;
      }

      .scores {
        flex-grow: 1;
        display: flex;
        margin-left: 20px;
        align-items: center;
      }

      .toggle-container {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .evaluation-toggle {
          height: fit-content;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 2px 7px;
          color: #1DA1F2;
          cursor: pointer;
        }
      }
    }

    .gpt-evaluation {
      font-size: 18px;
      line-height: 21px;
    }
  }

  .select-tweet {
    display: flex;
    flex-direction: column;
    padding: 18px 24px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .title {
        font-size: 28px;
        line-height: 33px;
      }

      .reply-btn {
        padding: 13px 16px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 360deg;
  }
}

@media only screen and (max-width: 991px) {
  .vault-container {
    padding: 0 16px;

    .miner-title {
      font-size: 32px;
      line-height: 38px;
      font-family: Gilroy-Bold;
    }

    .miner-description {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 35px;
    }

    .post-tweet {
      flex-direction: column;

      .post-info {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 27px;
        width: 100%;
        text-align: left;
      }

      .twit-btn {
        width: 100%;
      }
    }

    .miner-tweet {
      border-width: 2px;
      padding: 12px;

      .section-card {
        padding: 12px;

        &+.section-card {
          margin-top: 12px;
        }
      }

      .miner-tweet-content {
        border-radius: 10px;

        &.original {
          margin-bottom: 12px;
        }

        .avatar-container {
          .avatar {
            width: 38px;
            height: 38px;
          }
        }

        .tweet-content {
          .user-row {
            margin-bottom: 8px;

            .username {
              font-size: 14px;
              line-height: 16px;
            }

            .twitter-name {
              font-size: 14px;
              line-height: 16px;
            }
          }

          .replying-row {
            margin-bottom: 8px;
            font-size: 14px;
            line-height: 17px;
          }

          .content-row {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      .gpt-score {
        flex-direction: column;
        align-items: flex-start;

        .label {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 12px;
        }

        .scores {
          margin-left: 0;
          width: 100%;

          .evaluation-toggle {
            font-size: 8px;
          }
        }
      }

      .gpt-evaluation {
        font-size: 14px;
        line-height: 17px;
      }

      .countdown-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.13);
        background: rgba(29, 161, 242, 0.16);
        padding: 12px;
        margin-bottom: 12px;

        .timer-label {
          color: #FFFFFF;
          opacity: 0.5;
        }

        .timer {
          display: flex;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          align-items: center;

          .value {
            min-width: 24px;
            background: rgba(0, 0, 0, 0.96);
            color: #FFFFFF;
            border-radius: 3px;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .colon {
            margin: 0 5px;
          }
        }
      }
    }

    .select-tweet {
      padding: 0;
      width: 100%;

      .header {
        align-items: center;

        .title {
          font-weight: unset;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
  }
}